<ng-container *ngIf="currentUser$ | async as currentUser; else notSignedIn">
    <homepage-user-portal></homepage-user-portal>
</ng-container>

<ng-template #notSignedIn>
    <section class="hero">
        <div class="hero__info">
            <span class="hero__name">Groundwater Accounting Platform</span>
            <h1 class="hero__title">
                <span class="line line-1">Creating a sustainable future</span>
                <br />
                <span class="line line-2">for water resources</span>
            </h1>
        </div>

        <div class="hero__photo">
            <img src="../assets/main/home/hero.jpg" alt="Photo of California farmlands" />
        </div>

        <div class="hero__actions card">
            <div class="card-header">
                <h3 class="card-title">Welcome.</h3>
            </div>

            <div class="card-body">
                <div class="logged-out">
                    <div class="hero__btns">
                        <div class="hero__btns-wrapper">
                            <a (click)="login()" class="btn btn-primary">Sign In</a>
                        </div>

                        <div class="hero__btns-wrapper">
                            <a (click)="signUp()" class="btn btn-secondary-outline"> Create an Account </a>
                        </div>

                        <!-- <div class="hero__btns-wrapper">
                            <a [routerLink]="['request-support']" class="btn btn-secondary"> Request Support </a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="alert-container grid-12">
        <app-alert-display></app-alert-display>
    </section>
</ng-template>

