<div class="full-width-page">
    <div class="page-header centered">
        <h1 class="page-title">
            <span>
                <icon class="page-header-icon" icon="Star"></icon>
                Acknowledgments
            </span>
        </h1>
    </div>

    <section class="partners grid-12">
        <div class="partners__group">
            <div class="sub-header">
                <h4 class="sub-header__title">Platform Partners</h4>

                <div class="partners__copy copy copy-2">
                    <p>
                        The Groundwater Accounting Platform is developed and hosted by <a href="https://esassoc.com">Environmental Science Associates</a>. Scenario Planner
                        functionality is developed by&nbsp;<a href="https://olsson.com">Olsson</a> using the <a href="https://get.olsson.com">Groundwate Evaluation Toolbox</a>. The
                        Groundwater Accounting Platform is made possible through a partnership between the&nbsp;<a href="https://cawaterdata.org/"
                            >California Water Data Consortium</a
                        >
                        and the <a href="https://edf.org">Environmental Defense Fund</a>.
                    </p>
                </div>
            </div>

            <div class="partners__grid">
                <div class="partners__item">
                    <a href="https://edf.org/" class="partners__link" target="blank">
                        <img src="../assets/main/home/edf.png" alt="Environmental Defense Fund" />
                    </a>
                </div>

                <div class="partners__item">
                    <a href="https://cawaterdata.org/" class="partners__link" target="blank">
                        <img src="../assets/main/home/cwdc.png" alt="California Water Data Consortium" />
                    </a>
                </div>

                <div class="partners__item">
                    <a href="https://esassoc.com/" class="partners__link" target="blank">
                        <img src="../assets/main/home/esa.png" alt="ESA" />
                    </a>
                </div>

                <div class="partners__item">
                    <a href="https://www.olsson.com/" class="partners__link" target="blank">
                        <img src="../assets/main/home/olsson.png" alt="Olsson" />
                    </a>
                </div>
            </div>
        </div>

        <div class="partners__group">
            <div class="sub-header">
                <h4 class="sub-header__title">User Community</h4>

                <div class="partners__copy copy copy-2">
                    <p>Platform functionalities were developed in partnership with our user community.</p>
                </div>
            </div>

            <div class="partners__grid">
                <div class="partners__item">
                    <a href="https://www.miugsa.org/" class="partners__link" target="blank">
                        <img src="../assets/main/home/miugsa.png" alt="Merced Irrigation-Urban Groundwater Sustainability Agency" />
                    </a>
                </div>

                <div class="partners__item">
                    <a href="https://mercedsubbasingsa.org/" class="partners__link" target="blank">
                        <img src="../assets/main/home/msgsa.png" alt="Merced Subbasin Groundwater Sustainability Agency" />
                    </a>
                </div>

                <div class="partners__item">
                    <a href="https://www.pvwater.org/" class="partners__link" target="blank">
                        <img src="../assets/main/home/pajaro.png" alt="Pajaro Valley Water Management Agency" />
                    </a>
                </div>

                <div class="partners__item">
                    <a href="https://www.rrbwsd.com/" class="partners__link" target="blank">
                        <img src="../assets/main/home/rrb.png" alt="Rosedale-Rio Bravo Water Storage District" />
                    </a>
                </div>

                <div class="partners__item">
                    <a href="https://www.yologroundwater.org/" class="partners__link" target="blank">
                        <img src="../assets/main/home/yolo.png" alt="Yolo Subbasin Groundwater Agency" />
                    </a>
                </div>

                <div class="partners__item">
                    <a href="https://turlockgroundwater.org/" class="partners__link" target="blank">
                        <img src="../assets/main/logos/east-turlock-logo.png" alt="East Turlock Subbasin GSA" />
                    </a>
                </div>
            </div>
        </div>

        <div class="partners__group">
            <div class="sub-header">
                <h4 class="sub-header__title">Financial Partners</h4>

                <div class="partners__copy copy copy-2">
                    <p>
                        The Platform was developed with financial support from the California Department of Water Resources and U.S. Bureau of Reclamation WaterSMART Applied
                        Science Grant No. R22AP00237-00. Additional advisory support has been provided by the California State Water Resource Control Board and the California
                        Natural Resources Agency.
                    </p>
                </div>
            </div>

            <div class="partners__grid">
                <div class="partners__item">
                    <a href="https://water.ca.gov/" class="partners__link" target="blank">
                        <img src="../assets/main/home/dwr.png" alt="California Department of Water Resources" />
                    </a>
                </div>

                <div class="partners__item">
                    <a href="https://www.waterboards.ca.gov/" class="partners__link" target="blank">
                        <img src="../assets/main/home/swrcb.png" alt="California State Water Resources Control Board" />
                    </a>
                </div>

                <div class="partners__item">
                    <a href="https://resources.ca.gov/" class="partners__link" target="blank">
                        <img src="../assets/main/home/cnra.png" alt="California Natural Resources Agency" />
                    </a>
                </div>

                <div class="partners__item">
                    <a href="https://www.usbr.gov/" class="partners__link" target="blank">
                        <img src="../assets/main/logos/usbr.jpg" alt="U.S. Bureau of Reclamation" />
                    </a>
                </div>
            </div>
        </div>

        <div class="partners__group">
            <div class="sub-header">
                <h4 class="sub-header__title">Advisors</h4>

                <div class="partners__copy copy copy-2">
                    <p>
                        Individuals and members of various organizations have served on our advisory committees alongside pilot partners to help determine outcomes, features,
                        terminology, and the direction of the Platform.
                    </p>
                </div>
            </div>

            <ul class="partners__list">
                <li>Association of California Water Agencies</li>
                <li>California Farm Bureau</li>
                <li>Clean Water Action</li>
                <li>Community Water Center</li>
                <li>David Orth</li>
                <li>Department of Water Resources</li>
                <li>The Freshwater Trust</li>
                <li>GEI Consultants</li>
                <li>Geosyntec</li>
                <li>Greater Kaweah GSA</li>
                <li>Internet of Water</li>
                <li>Kern Groundwater Authority</li>
                <li>Lawrence Berkeley National Laboratory</li>
                <li>Lindmore Irrigation District</li>
                <li>Madera Farm Bureau</li>
                <li>The Nature Conservancy</li>
                <li>Parker Groundwater</li>
                <li>Public Policy Institute of California</li>
                <li>Renewable Resources Group</li>
                <li>Savannah Tjaden</li>
                <li>Solano County Water Agency</li>
                <li>Sonoma County Water Agency</li>
                <li>State Water Resources Control Board</li>
                <li>Sustainable Conservation</li>
                <li>Tulare Irrigation District</li>
                <li>UC Fresno Center for Irrigation</li>
                <li>UC Merced - Secure Water Future</li>
                <li>US Geological Survey</li>
                <li>Valley Water</li>
                <li>Westchester Group Investor Management</li>
                <li>The Wonderful Company</li>
                <li>Yuba Water Agency</li>
                <li>Zanjero</li>
                <li>Zone 7 Water Agency</li>
            </ul>
        </div>
    </section>
</div>
