/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateWaterAccountFromSuggestionDto } from '../model/create-water-account-from-suggestion-dto';
import { ParcelMinimalDto } from '../model/parcel-minimal-dto';
import { UserDto } from '../model/user-dto';
import { WaterAccountBudgetReportDto } from '../model/water-account-budget-report-dto';
import { WaterAccountCreateDto } from '../model/water-account-create-dto';
import { WaterAccountDto } from '../model/water-account-dto';
import { WaterAccountIndexGridDto } from '../model/water-account-index-grid-dto';
import { WaterAccountMinimalDto } from '../model/water-account-minimal-dto';
import { WaterAccountSuggestionDto } from '../model/water-account-suggestion-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class WaterAccountByGeographyService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param geographyID 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountsBudgetReportsYearsYearGet(geographyID: number, year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountBudgetReportDto>>;
    public geographiesGeographyIDWaterAccountsBudgetReportsYearsYearGet(geographyID: number, year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountBudgetReportDto>>>;
    public geographiesGeographyIDWaterAccountsBudgetReportsYearsYearGet(geographyID: number, year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountBudgetReportDto>>>;
    public geographiesGeographyIDWaterAccountsBudgetReportsYearsYearGet(geographyID: number, year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountsBudgetReportsYearsYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling geographiesGeographyIDWaterAccountsBudgetReportsYearsYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountBudgetReportDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-accounts/budget-reports/years/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountsCurrentUserGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountIndexGridDto>>;
    public geographiesGeographyIDWaterAccountsCurrentUserGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountIndexGridDto>>>;
    public geographiesGeographyIDWaterAccountsCurrentUserGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountIndexGridDto>>>;
    public geographiesGeographyIDWaterAccountsCurrentUserGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountsCurrentUserGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountIndexGridDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-accounts/current-user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param waterAccountCreateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountsPost(geographyID: number, waterAccountCreateDto?: WaterAccountCreateDto, observe?: 'body', reportProgress?: boolean): Observable<WaterAccountDto>;
    public geographiesGeographyIDWaterAccountsPost(geographyID: number, waterAccountCreateDto?: WaterAccountCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterAccountDto>>;
    public geographiesGeographyIDWaterAccountsPost(geographyID: number, waterAccountCreateDto?: WaterAccountCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterAccountDto>>;
    public geographiesGeographyIDWaterAccountsPost(geographyID: number, waterAccountCreateDto?: WaterAccountCreateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<WaterAccountDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-accounts`,
            waterAccountCreateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param createWaterAccountFromSuggestionDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountsSuggestedBulkCreatePost(geographyID: number, createWaterAccountFromSuggestionDto?: Array<CreateWaterAccountFromSuggestionDto>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDWaterAccountsSuggestedBulkCreatePost(geographyID: number, createWaterAccountFromSuggestionDto?: Array<CreateWaterAccountFromSuggestionDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDWaterAccountsSuggestedBulkCreatePost(geographyID: number, createWaterAccountFromSuggestionDto?: Array<CreateWaterAccountFromSuggestionDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDWaterAccountsSuggestedBulkCreatePost(geographyID: number, createWaterAccountFromSuggestionDto?: Array<CreateWaterAccountFromSuggestionDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountsSuggestedBulkCreatePost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-accounts/suggested/bulk-create`,
            createWaterAccountFromSuggestionDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param createWaterAccountFromSuggestionDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountsSuggestedCreatePost(geographyID: number, createWaterAccountFromSuggestionDto?: CreateWaterAccountFromSuggestionDto, observe?: 'body', reportProgress?: boolean): Observable<WaterAccountMinimalDto>;
    public geographiesGeographyIDWaterAccountsSuggestedCreatePost(geographyID: number, createWaterAccountFromSuggestionDto?: CreateWaterAccountFromSuggestionDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterAccountMinimalDto>>;
    public geographiesGeographyIDWaterAccountsSuggestedCreatePost(geographyID: number, createWaterAccountFromSuggestionDto?: CreateWaterAccountFromSuggestionDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterAccountMinimalDto>>;
    public geographiesGeographyIDWaterAccountsSuggestedCreatePost(geographyID: number, createWaterAccountFromSuggestionDto?: CreateWaterAccountFromSuggestionDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountsSuggestedCreatePost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<WaterAccountMinimalDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-accounts/suggested/create`,
            createWaterAccountFromSuggestionDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountsSuggestedGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountSuggestionDto>>;
    public geographiesGeographyIDWaterAccountsSuggestedGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountSuggestionDto>>>;
    public geographiesGeographyIDWaterAccountsSuggestedGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountSuggestionDto>>>;
    public geographiesGeographyIDWaterAccountsSuggestedGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountsSuggestedGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountSuggestionDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-accounts/suggested`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountsSuggestedRejectPost(geographyID: number, requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDWaterAccountsSuggestedRejectPost(geographyID: number, requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDWaterAccountsSuggestedRejectPost(geographyID: number, requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDWaterAccountsSuggestedRejectPost(geographyID: number, requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountsSuggestedRejectPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-accounts/suggested/reject`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountsWaterAccountHoldersGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<UserDto>>;
    public geographiesGeographyIDWaterAccountsWaterAccountHoldersGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserDto>>>;
    public geographiesGeographyIDWaterAccountsWaterAccountHoldersGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserDto>>>;
    public geographiesGeographyIDWaterAccountsWaterAccountHoldersGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountsWaterAccountHoldersGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-accounts/water-account-holders`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param waterAccountID 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountsWaterAccountIDParcelsYearsYearGet(geographyID: number, waterAccountID: number, year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelMinimalDto>>;
    public geographiesGeographyIDWaterAccountsWaterAccountIDParcelsYearsYearGet(geographyID: number, waterAccountID: number, year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelMinimalDto>>>;
    public geographiesGeographyIDWaterAccountsWaterAccountIDParcelsYearsYearGet(geographyID: number, waterAccountID: number, year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelMinimalDto>>>;
    public geographiesGeographyIDWaterAccountsWaterAccountIDParcelsYearsYearGet(geographyID: number, waterAccountID: number, year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountsWaterAccountIDParcelsYearsYearGet.');
        }

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling geographiesGeographyIDWaterAccountsWaterAccountIDParcelsYearsYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling geographiesGeographyIDWaterAccountsWaterAccountIDParcelsYearsYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelMinimalDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-accounts/${encodeURIComponent(String(waterAccountID))}/parcels/years/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
