<ng-container *ngIf="currentUser$ | async">
    <ng-container *ngIf="geography$ | async as geography">
        <water-dashboard-nav [geography]="geography" (waterAccountCreated)="onWaterAccountCreated()"> </water-dashboard-nav>

        <div class="full-width-page">
            <page-header
                preTitle="Water Dashboard"
                pageTitle="Water Accounts"
                [customRichTextTypeID]="richTextID"
                icon="WaterAccounts"
                [templateBottomRight]="templateBottomRight"></page-header>

            <ng-template #templateBottomRight>
                <button routerLink="/water-accounts/request-changes" class="btn btn-orange request-changes-button" [disabled]="isLoading || currentUserHasNoGeographies">
                    Request Water Account Changes
                </button>
            </ng-template>

            <div class="page-body" *ngIf="currentUserGeographies$ | async as currentUserGeographies" [loadingSpinner]="{ isLoading: isLoading && firstLoad, loadingHeight: 700 }">
                <app-alert-display></app-alert-display>

                <ng-container *ngIf="!currentUserHasNoGeographies; else noWaterAccountsMessage">
                    <ng-container *ngIf="waterAccounts$ | async as waterAccounts">
                        <qanat-grid-header
                            *ngIf="gridRef"
                            [grid]="gridRef"
                            [rowDataCount]="waterAccounts?.length"
                            [leftAlignClearFiltersButton]="true"
                            [disableGlobalFilter]="selectedPanel === 'Map'">
                            <div customGridActionsCenter>
                                <div class="button-group tab-nav">
                                    <button class="button-group__item btn-sm" [class.active]="selectedPanel === 'Grid'" (click)="toggleSelectedPanel('Grid')">
                                        <icon icon="BulletedList"></icon>
                                        Grid
                                    </button>
                                    <button class="button-group__item btn-sm" [class.active]="selectedPanel === 'Hybrid'" (click)="toggleSelectedPanel('Hybrid')">
                                        <icon icon="Layout"></icon>
                                        Hybrid
                                    </button>
                                    <button class="button-group__item btn-sm" [class.active]="selectedPanel === 'Map'" (click)="toggleSelectedPanel('Map')">
                                        <icon icon="VerticalMap"></icon>
                                        Map
                                    </button>
                                </div>
                            </div>

                            <div customGridActionsRight>
                                <div class="form-field" class="geography-selector">
                                    <div class="field">
                                        <select name="Geography" [(ngModel)]="currentGeography" (ngModelChange)="onGeographySelected($event)" [compareWith]="compareGeography">
                                            <option *ngFor="let geography of currentUserGeographies" [ngValue]="geography">
                                                {{ geography.GeographyName }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </qanat-grid-header>

                        <div
                            class="grid-12 mt-3"
                            [loadingSpinner]="{
                                isLoading: isLoading && !firstLoad,
                                loadingHeight: 500,
                            }">
                            <div
                                class="tab-nav-panel"
                                [class.g-col-6]="selectedPanel === 'Hybrid'"
                                [class.g-col-12]="selectedPanel === 'Grid'"
                                [class.hidden]="selectedPanel === 'Map'">
                                <qanat-grid
                                    height="675px"
                                    [rowData]="waterAccounts"
                                    [columnDefs]="columnDefs$ | async"
                                    [overrideDefaultGridHeader]="true"
                                    rowSelection="single"
                                    (gridReady)="onGridReady($event)"
                                    (gridRefReady)="onGridRefReady($event)"
                                    (selectionChanged)="onGridSelectionChanged()"
                                    downloadFileName="{{ geography.GeographyName.toLowerCase() }}-water-accounts"
                                    [colIDsToExclude]="currentUserHasManagerPermissionsForSelectedGeography ? ['0'] : []"></qanat-grid>
                            </div>

                            <div
                                class="tab-nav-panel"
                                [class.g-col-6]="selectedPanel === 'Hybrid'"
                                [class.g-col-12]="selectedPanel === 'Map'"
                                [class.hidden]="selectedPanel === 'Grid'">
                                <qanat-map class="location-card" mapHeight="720px" (onMapLoad)="handleMapReady($event)">
                                    <ng-container *ngIf="mapIsReady">
                                        <ng-container *ngIf="waterAccountIDs$ | async as waterAccountIDs">
                                            <ng-container *ngIf="wells$ | async as wells">
                                                <water-accounts-layer
                                                    [geographyID]="geography.GeographyID"
                                                    [waterAccountIDs]="waterAccountIDs"
                                                    [selectedWaterAccountID]="selectedWaterAccountID"
                                                    [wells]="wells"
                                                    [map]="map"
                                                    [layerControl]="layerControl"
                                                    (layerBoundsCalculated)="handleLayerBoundsCalculated($event)"
                                                    (waterAccountSelected)="onMapSelectionChanged($event)">
                                                </water-accounts-layer>
                                            </ng-container>
                                        </ng-container>

                                        <gsa-boundaries *ngIf="geography" [displayOnLoad]="false" [map]="map" [geographyID]="geography.GeographyID" [layerControl]="layerControl">
                                        </gsa-boundaries>

                                        <zone-group-layer
                                            *ngFor="let zoneGroup of zoneGroups$ | async"
                                            [displayOnLoad]="false"
                                            [zoneGroupID]="zoneGroup.ZoneGroupID"
                                            [zoneGroupName]="zoneGroup.ZoneGroupName"
                                            [map]="map"
                                            [layerControl]="layerControl">
                                        </zone-group-layer>

                                        <geography-external-map-layer
                                            *ngFor="let externalMapLayer of externalMapLayers$ | async"
                                            [map]="map"
                                            [layerControl]="layerControl"
                                            [externalMapLayer]="externalMapLayer">
                                        </geography-external-map-layer>
                                    </ng-container>
                                </qanat-map>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-template #noWaterAccountsMessage>
                    <div class="alert alert-info">
                        <div class="alert-content">
                            <i class="fa fa-info"></i>
                            Your user profile does not currently have access to any Water Accounts. Claim Water Accounts to get started or contact a water manager for your
                            geography for assistance.
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </ng-container>
</ng-container>
