import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { forkJoin, of, Subscription } from "rxjs";
import { routeParams } from "src/app/app.routes";
import { UsageEntityService } from "src/app/shared/generated/api/usage-entity.service";
import { WaterAccountService } from "src/app/shared/generated/api/water-account.service";
import {
    AllocationPlanMinimalDto,
    ExternalMapLayerDto,
    GeographySimpleDto,
    MonthlyUsageSummaryDto,
    ParcelMinimalDto,
    UserDto,
    WaterAccountBudgetStatDto,
    WaterAccountDto,
    WaterAccountMinimalDto,
    WaterAccountParcelWaterMeasurementDto,
    WaterAccountWaterTypeMonthlySupplyDto,
    WaterTypeSimpleDto,
    ZoneGroupMinimalDto,
} from "src/app/shared/generated/model/models";
import { GeographyEnum } from "src/app/shared/models/enums/geography.enum";
import { GroupByPipe } from "src/app/shared/pipes/group-by.pipe";
import { SumPipe } from "src/app/shared/pipes/sum.pipe";
import { NgIf, NgClass, NgFor, DecimalPipe, PercentPipe, DatePipe } from "@angular/common";
import { PageHeaderComponent } from "src/app/shared/components/page-header/page-header.component";
import { IconComponent } from "src/app/shared/components/icon/icon.component";
import { KeyValuePairListComponent } from "src/app/shared/components/key-value-pair-list/key-value-pair-list.component";
import { KeyValuePairComponent } from "src/app/shared/components/key-value-pair/key-value-pair.component";
import { ButtonGroupComponent } from "src/app/shared/components/button-group/button-group.component";
import { ModelNameTagComponent } from "src/app/shared/components/name-tag/name-tag.component";
import { ReportingPeriodSelectComponent } from "src/app/shared/components/reporting-period-select/reporting-period-select.component";
import { VegaCumulativeUsageChartComponent } from "src/app/shared/components/vega/vega-cumulative-usage-chart/vega-cumulative-usage-chart.component";
import { VegaMonthlyUsageChartComponent } from "src/app/shared/components/vega/vega-monthly-usage-chart/vega-monthly-usage-chart.component";
import { WaterSupplyTypeComponent } from "src/app/shared/components/water-supply-type/water-supply-type.component";
import { LoadingDirective } from "src/app/shared/directives/loading.directive";
import { WaterTypeByGeographyService } from "src/app/shared/generated/api/water-type-by-geography.service";
import { ParcelSupplyByGeographyService } from "src/app/shared/generated/api/parcel-supply-by-geography.service";
import { WaterAccountParcelWaterMeasurementsGridComponent } from "./components/water-account-parcel-water-measurements-grid/water-account-parcel-water-measurements-grid.component";
import { QanatMapComponent, QanatMapInitEvent } from "src/app/shared/components/leaflet/qanat-map/qanat-map.component";
import { ParcelLayerComponent } from "src/app/shared/components/leaflet/layers/parcel-layer/parcel-layer.component";
import { GsaBoundariesComponent } from "src/app/shared/components/leaflet/layers/gsa-boundaries/gsa-boundaries.component";
import { ZoneGroupLayerComponent } from "src/app/shared/components/leaflet/layers/zone-group-layer/zone-group-layer.component";
import { GeographyExternalMapLayerComponent } from "src/app/shared/components/leaflet/layers/geography-external-map-layer/geography-external-map-layer.component";
import { Map, layerControl } from "leaflet";
import { ZoneGroupService } from "src/app/shared/generated/api/zone-group.service";
import { ExternalMapLayerService } from "src/app/shared/generated/api/external-map-layer.service";
import { UsageEntitiesLayerComponent } from "src/app/shared/components/leaflet/layers/usage-entities-layer/usage-entities-layer.component";
import { WaterAccountParcelService } from "src/app/shared/generated/api/water-account-parcel.service";

@Component({
    selector: "water-budget",
    templateUrl: "./water-budget.component.html",
    styleUrls: ["./water-budget.component.scss"],
    standalone: true,
    imports: [
        LoadingDirective,
        NgIf,
        PageHeaderComponent,
        ModelNameTagComponent,
        RouterLink,
        ReportingPeriodSelectComponent,
        ButtonGroupComponent,
        NgClass,
        IconComponent,
        KeyValuePairListComponent,
        KeyValuePairComponent,
        VegaCumulativeUsageChartComponent,
        VegaMonthlyUsageChartComponent,
        WaterAccountParcelWaterMeasurementsGridComponent,
        NgFor,
        WaterSupplyTypeComponent,
        DecimalPipe,
        PercentPipe,
        DatePipe,
        QanatMapComponent,
        ParcelLayerComponent,
        GsaBoundariesComponent,
        ZoneGroupLayerComponent,
        GeographyExternalMapLayerComponent,
        UsageEntitiesLayerComponent,
    ],
})
export class WaterBudgetComponent implements OnInit, OnDestroy {
    public currentUser: UserDto;

    public waterAccountID: number;
    private accountIDSubscription: Subscription = Subscription.EMPTY;
    public currentWaterAccount: WaterAccountMinimalDto;
    public currentGeography: GeographySimpleDto;
    public currentGeographySlug: string;

    public selectedYear: number;
    public waterTypes: WaterTypeSimpleDto[];
    public waterTypesSupply: WaterAccountWaterTypeMonthlySupplyDto[];
    public parcelIDs: number[];
    public parcels: ParcelMinimalDto[];
    public allocationPlans: AllocationPlanMinimalDto[];

    public totalSupply: number;
    public usageToDate: number;
    public usageBar: string;
    public totalAcreage: number;
    public parcelArea: number;
    public usageEntitiesArea: number;
    public currentAvailable: number;

    public totalET: number;
    public totalEffectivePrecip: number;

    public waterBudgetStats: WaterAccountBudgetStatDto;

    public showAcresFeet: boolean = false;
    public acresFeetUnits: string = "ac-ft";
    public acresFeetAcreUnits: string = "ac-ft/ac";
    public mostRecentSupplyDate: string;
    public mostRecentUsageDate: string;
    public mostRecentEffectiveDate: string;

    public showCumulativeWaterUsageChart = true;
    public showWaterAccountRollup = true;
    public isLoading: boolean = true;

    public map: Map;
    public layerControl: layerControl;
    public mapIsReady: boolean = false;
    public zoneGroups: ZoneGroupMinimalDto[];
    public externalMapLayers: ExternalMapLayerDto[];

    public waterAccountParcelWaterMeasurements: WaterAccountParcelWaterMeasurementDto[];
    public sourceOfRecordWaterMeasurements: WaterAccountParcelWaterMeasurementDto[];
    public monthlyUsageSummaries: MonthlyUsageSummaryDto[];

    constructor(
        private parcelSupplyByGeographyService: ParcelSupplyByGeographyService,
        private waterTypeByGeographyService: WaterTypeByGeographyService,
        private route: ActivatedRoute,
        private waterAccountService: WaterAccountService,
        private waterAccountParcelService: WaterAccountParcelService,
        private usageEntityService: UsageEntityService,
        private zoneGroupService: ZoneGroupService,
        private externalMapLayerService: ExternalMapLayerService,
        private sumPipe: SumPipe,
        private groupByPipe: GroupByPipe,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnDestroy() {
        this.accountIDSubscription.unsubscribe();
        this.cdr.detach();
    }

    ngOnInit(): void {
        this.accountIDSubscription = this.route.paramMap.subscribe((paramMap) => {
            this.isLoading = true;
            this.waterAccountID = parseInt(paramMap.get(routeParams.waterAccountID));
            this.waterAccountService.waterAccountsWaterAccountIDGet(this.waterAccountID).subscribe((waterAccount) => {
                this.currentWaterAccount = waterAccount;
                this.currentGeography = waterAccount.Geography;
                this.currentGeographySlug = waterAccount.Geography.GeographyName.replace(" ", "-").toLowerCase();
                this.selectedYear = waterAccount.Geography.DefaultDisplayYear;
                this.getDataFromWaterAccountAndGeographyID(this.currentWaterAccount, this.currentGeography.GeographyID);
            });
        });
    }

    getDataFromWaterAccountAndGeographyID(waterAccount: WaterAccountDto, geographyID: number) {
        const budgetStatRequest = this.currentGeography.ShowSupplyOnWaterBudgetComponent
            ? of(null)
            : this.waterAccountService.waterAccountsWaterAccountIDWaterBudgetStatsYearsYearGet(waterAccount.WaterAccountID, this.selectedYear);

        forkJoin({
            waterTypes: this.waterTypeByGeographyService.geographiesGeographyIDWaterTypesActiveGet(geographyID),
            zoneGroups: this.zoneGroupService.geographiesGeographyIDZoneGroupsGet(geographyID),
            externalMapLayers: this.externalMapLayerService.geographiesGeographyIDExternalMapLayersGet(geographyID),
            parcels: this.waterAccountParcelService.waterAccountsWaterAccountIDParcelsMinimalsGet(waterAccount.WaterAccountID),
            usageEntities: this.usageEntityService.waterAccountsWaterAccountIDUsageEntitiesGet(waterAccount.WaterAccountID),
            allocationPlans: this.waterAccountService.waterAccountsWaterAccountIDAllocationPlansGet(waterAccount.WaterAccountID),
            budgetStats: budgetStatRequest,
        }).subscribe(({ waterTypes, zoneGroups, externalMapLayers, parcels, usageEntities, allocationPlans, budgetStats }) => {
            this.parcelArea = this.sumPipe.transform(parcels, "ParcelArea");
            this.usageEntitiesArea = this.sumPipe.transform(usageEntities, "UsageEntityArea");
            this.parcels = parcels;
            this.parcelIDs = parcels.map((x) => x.ParcelID);
            this.allocationPlans = allocationPlans;
            this.zoneGroups = zoneGroups;
            this.externalMapLayers = externalMapLayers;
            this.waterBudgetStats = budgetStats;

            forkJoin({
                parcelWaterMeasurements: this.waterAccountService.waterAccountsWaterAccountIDParcelSuppliesYearsYearMonthlyUsageSummaryGet(
                    waterAccount.WaterAccountID,
                    this.selectedYear
                ),
                totalWaterTypeSupplyData: this.waterAccountService.waterAccountsWaterAccountIDWaterTypeMonthlySupplyYearsYearGet(waterAccount.WaterAccountID, this.selectedYear),
                mostRecentEffectiveDates: this.parcelSupplyByGeographyService.geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDRecentEffectiveDatesYearYearGet(
                    geographyID,
                    this.selectedYear,
                    waterAccount.WaterAccountID
                ),
            }).subscribe(({ parcelWaterMeasurements, totalWaterTypeSupplyData, mostRecentEffectiveDates }) => {
                this.waterAccountParcelWaterMeasurements = parcelWaterMeasurements;

                this.waterAccountParcelWaterMeasurements.forEach((measurement) => {
                    (measurement as any).$ParcelArea = this.parcels.find((x) => x.ParcelID == measurement.ParcelID).ParcelArea;
                });

                this.waterTypes = waterTypes;
                this.waterTypesSupply = totalWaterTypeSupplyData;
                this.mostRecentSupplyDate = this.getDateFromString(mostRecentEffectiveDates.MostRecentSupplyEffectiveDate);
                this.mostRecentUsageDate = this.getDateFromString(mostRecentEffectiveDates.MostRecentUsageEffectiveDate);
                this.mostRecentEffectiveDate = this.mostRecentSupplyDate > this.mostRecentUsageDate ? this.mostRecentSupplyDate : this.mostRecentUsageDate;
                this.setSupplyAndUsageValues();
                this.isLoading = false;
            });
        });
    }

    getSourceOfRecordWaterMeasurementRollups(measurements: WaterAccountParcelWaterMeasurementDto[]): MonthlyUsageSummaryDto[] {
        const rollupData = [];
        const groupedByEffectiveDate = this.groupByPipe.transform(
            measurements.flatMap((x) => x.WaterMeasurementMonthlyValues),
            "EffectiveDate"
        );

        const parcelIDsToConsider = measurements.map((x) => x.ParcelID);

        const parcelArea = this.parcels
            .filter((p) => {
                return parcelIDsToConsider.includes(p.ParcelID);
            })
            .map((p) => p.ParcelArea)
            .reduce((a, b) => a + b);

        Object.keys(groupedByEffectiveDate).forEach((effectiveDate) => {
            const monthlyUsageSummary = new MonthlyUsageSummaryDto();
            const group = groupedByEffectiveDate[effectiveDate];

            monthlyUsageSummary.EffectiveDate = effectiveDate;

            const currentUsageAmount = this.sumPipe.transform(group, "CurrentUsageAmount");
            monthlyUsageSummary.CurrentUsageAmount = currentUsageAmount;
            monthlyUsageSummary.CurrentUsageAmountDepth = currentUsageAmount / parcelArea;

            const averageUsageAmount = this.sumPipe.transform(group, "AverageUsageAmount");
            monthlyUsageSummary.AverageUsageAmount = averageUsageAmount;
            monthlyUsageSummary.AverageUsageAmountDepth = averageUsageAmount / parcelArea;

            const currentCumulativeUsageAmount = group.map((value) => value.CurrentCumulativeUsageAmount).reduce((a, b) => (a == null ? null : a + b));
            monthlyUsageSummary.CurrentCumulativeUsageAmount = currentCumulativeUsageAmount == null ? null : currentCumulativeUsageAmount > 0 ? currentCumulativeUsageAmount : 0;
            monthlyUsageSummary.CurrentCumulativeUsageAmountDepth =
                currentCumulativeUsageAmount == null ? null : currentCumulativeUsageAmount > 0 ? currentCumulativeUsageAmount / parcelArea : 0;

            const averageCumulativeUsageAmount = group.map((value) => value.AverageCumulativeUsageAmount).reduce((a, b) => (a == null ? null : a + b));
            monthlyUsageSummary.AverageCumulativeUsageAmount = averageCumulativeUsageAmount == null ? null : averageCumulativeUsageAmount > 0 ? averageCumulativeUsageAmount : 0;
            monthlyUsageSummary.AverageCumulativeUsageAmountDepth =
                averageCumulativeUsageAmount == null ? null : averageCumulativeUsageAmount > 0 ? averageCumulativeUsageAmount / parcelArea : 0;

            rollupData.push(monthlyUsageSummary);
        });
        return rollupData;
    }

    setSupplyAndUsageValues() {
        this.sourceOfRecordWaterMeasurements = this.waterAccountParcelWaterMeasurements.filter(
            (x) => x.WaterMeasurementTypeID === this.currentGeography.SourceOfRecordWaterMeasurementTypeID
        );

        const parcelIDsToConsider = this.sourceOfRecordWaterMeasurements.map((x) => x.ParcelID);
        const parcelArea = this.parcels
            .filter((p) => parcelIDsToConsider.includes(p.ParcelID))
            .map((p) => p.ParcelArea)
            .reduce((a, b) => a + b);

        if (this.sourceOfRecordWaterMeasurements.length > 0) {
            this.usageToDate = this.sumPipe.transform(this.sourceOfRecordWaterMeasurements, "WaterMeasurementTotalValue");
            this.totalAcreage = parcelArea;
        } else {
            this.usageToDate = null;
        }

        if (this.waterTypesSupply.length > 0) {
            this.totalSupply = this.sumPipe.transform(this.waterTypesSupply, "TotalSupply");
        } else {
            this.totalSupply = null;
        }
        this.currentAvailable = this.totalSupply - this.usageToDate;
        this.usageBar = "width: " + this.getPercentageOfWaterUsed() * 100 + "%";

        this.monthlyUsageSummaries = this.getSourceOfRecordWaterMeasurementRollups(this.sourceOfRecordWaterMeasurements);

        if (this.currentGeography.GeographyID == GeographyEnum.etsgsa) {
            const etValues = this.waterAccountParcelWaterMeasurements.filter((x) => x.WaterMeasurementTypeName === "Land IQ ETa");
            this.totalET = this.sumPipe.transform(etValues, "WaterMeasurementTotalValue");
            const effectivePrecipValues = this.waterAccountParcelWaterMeasurements.filter((x) => x.WaterMeasurementTypeName === "Effective Precip");
            this.totalEffectivePrecip = this.sumPipe.transform(effectivePrecipValues, "WaterMeasurementTotalValue");
        }
    }

    getWaterTypeUsage(waterType: WaterTypeSimpleDto) {
        let returnValue = 0;

        if (!this.waterTypesSupply) {
            return returnValue;
        }

        const waterUse = this.waterTypesSupply.filter((x) => x.WaterTypeID == waterType.WaterTypeID);

        if (waterUse.length == 1) {
            returnValue = waterUse[0].TotalSupply;
        }

        if (!this.showAcresFeet) {
            returnValue = this.convertToAcresFeetAcre(returnValue);
        }

        return returnValue;
    }

    setWaterSupplyBar(waterTypeTotalUse) {
        return "width: " + (waterTypeTotalUse / this.totalSupply) * 100 + "%";
    }

    getPercentageOfWaterUsed(): number {
        if (this.totalSupply > 0) {
            return this.usageToDate / this.totalSupply;
        }
        return 0;
    }

    updateDashboardForSelectedYear(selectedYear: number) {
        this.selectedYear = selectedYear;

        forkJoin({
            parcelWaterMeasurements: this.waterAccountService.waterAccountsWaterAccountIDParcelSuppliesYearsYearMonthlyUsageSummaryGet(this.waterAccountID, this.selectedYear),
            totalWaterTypeSupplyData: this.waterAccountService.waterAccountsWaterAccountIDWaterTypeMonthlySupplyYearsYearGet(this.waterAccountID, this.selectedYear),
            mostRecentEffectiveDates: this.parcelSupplyByGeographyService.geographiesGeographyIDParcelSuppliesWaterAccountsWaterAccountIDRecentEffectiveDatesYearYearGet(
                this.currentGeography.GeographyID,
                this.selectedYear,
                this.waterAccountID
            ),
        }).subscribe(({ parcelWaterMeasurements, totalWaterTypeSupplyData, mostRecentEffectiveDates }) => {
            this.waterAccountParcelWaterMeasurements = parcelWaterMeasurements;

            this.waterAccountParcelWaterMeasurements.forEach((measurement) => {
                (measurement as any).$ParcelArea = this.parcels.find((x) => x.ParcelID == measurement.ParcelID).ParcelArea;
            });

            this.waterTypesSupply = totalWaterTypeSupplyData;
            this.mostRecentSupplyDate = this.getDateFromString(mostRecentEffectiveDates.MostRecentSupplyEffectiveDate);
            this.mostRecentUsageDate = this.getDateFromString(mostRecentEffectiveDates.MostRecentUsageEffectiveDate);
            this.mostRecentEffectiveDate = this.mostRecentSupplyDate > this.mostRecentUsageDate ? this.mostRecentSupplyDate : this.mostRecentUsageDate;
            this.setSupplyAndUsageValues();
        });
    }

    convertToAcresFeetAcre(num) {
        return num / this.totalAcreage;
    }

    changeUnits(temp) {
        this.showAcresFeet = temp;
    }

    getShowAcresFeet() {
        return this.showAcresFeet;
    }

    getDateFromString(dateString: string) {
        if (dateString != null) return dateString.substring(0, 10);
    }

    public updateShowCumulativeWaterUsageChart(value: boolean) {
        this.showCumulativeWaterUsageChart = value;
    }

    public updateShowWaterAccountRollup(value: boolean) {
        this.showWaterAccountRollup = value;
    }

    handleMapReady(event: QanatMapInitEvent): void {
        this.map = event.map;
        this.layerControl = event.layerControl;
        this.mapIsReady = true;
        this.cdr.detectChanges();
    }
}
