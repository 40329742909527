/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddUserByEmailDto } from '../model/add-user-by-email-dto';
import { OnboardWaterAccountPINDto } from '../model/onboard-water-account-pin-dto';
import { OnboardingWaterAccountDto } from '../model/onboarding-water-account-dto';
import { WaterAccountUserMinimalDto } from '../model/water-account-user-minimal-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class WaterAccountUserService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<OnboardingWaterAccountDto>>;
    public geographiesGeographyIDWaterAccountGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OnboardingWaterAccountDto>>>;
    public geographiesGeographyIDWaterAccountGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OnboardingWaterAccountDto>>>;
    public geographiesGeographyIDWaterAccountGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<OnboardingWaterAccountDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-account`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountPINsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<OnboardWaterAccountPINDto>>;
    public geographiesGeographyIDWaterAccountPINsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OnboardWaterAccountPINDto>>>;
    public geographiesGeographyIDWaterAccountPINsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OnboardWaterAccountPINDto>>>;
    public geographiesGeographyIDWaterAccountPINsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountPINsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<OnboardWaterAccountPINDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/waterAccountPINs`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param waterAccountPIN 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountWaterAccountPINWaterAccountPINPost(geographyID: number, waterAccountPIN: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDWaterAccountWaterAccountPINWaterAccountPINPost(geographyID: number, waterAccountPIN: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDWaterAccountWaterAccountPINWaterAccountPINPost(geographyID: number, waterAccountPIN: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDWaterAccountWaterAccountPINWaterAccountPINPost(geographyID: number, waterAccountPIN: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountWaterAccountPINWaterAccountPINPost.');
        }

        if (waterAccountPIN === null || waterAccountPIN === undefined) {
            throw new Error('Required parameter waterAccountPIN was null or undefined when calling geographiesGeographyIDWaterAccountWaterAccountPINWaterAccountPINPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-account/waterAccountPIN/${encodeURIComponent(String(waterAccountPIN))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param onboardingWaterAccountDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterAccountsClaimPost(geographyID: number, onboardingWaterAccountDto?: Array<OnboardingWaterAccountDto>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDWaterAccountsClaimPost(geographyID: number, onboardingWaterAccountDto?: Array<OnboardingWaterAccountDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDWaterAccountsClaimPost(geographyID: number, onboardingWaterAccountDto?: Array<OnboardingWaterAccountDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDWaterAccountsClaimPost(geographyID: number, onboardingWaterAccountDto?: Array<OnboardingWaterAccountDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterAccountsClaimPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-accounts/claim`,
            onboardingWaterAccountDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param userID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userUserIDWaterAccountsGet(userID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountUserMinimalDto>>;
    public userUserIDWaterAccountsGet(userID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountUserMinimalDto>>>;
    public userUserIDWaterAccountsGet(userID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountUserMinimalDto>>>;
    public userUserIDWaterAccountsGet(userID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling userUserIDWaterAccountsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountUserMinimalDto>>(`${this.basePath}/user/${encodeURIComponent(String(userID))}/water-accounts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param userID 
     * @param waterAccountUserMinimalDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userUserIDWaterAccountsPut(userID: number, waterAccountUserMinimalDto?: Array<WaterAccountUserMinimalDto>, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountUserMinimalDto>>;
    public userUserIDWaterAccountsPut(userID: number, waterAccountUserMinimalDto?: Array<WaterAccountUserMinimalDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountUserMinimalDto>>>;
    public userUserIDWaterAccountsPut(userID: number, waterAccountUserMinimalDto?: Array<WaterAccountUserMinimalDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountUserMinimalDto>>>;
    public userUserIDWaterAccountsPut(userID: number, waterAccountUserMinimalDto?: Array<WaterAccountUserMinimalDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling userUserIDWaterAccountsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<WaterAccountUserMinimalDto>>(`${this.basePath}/user/${encodeURIComponent(String(userID))}/water-accounts`,
            waterAccountUserMinimalDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param invitingUserID 
     * @param addUserByEmailDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDInvitingUserInvitingUserIDPost(waterAccountID: number, invitingUserID: number, addUserByEmailDto?: AddUserByEmailDto, observe?: 'body', reportProgress?: boolean): Observable<WaterAccountUserMinimalDto>;
    public waterAccountsWaterAccountIDInvitingUserInvitingUserIDPost(waterAccountID: number, invitingUserID: number, addUserByEmailDto?: AddUserByEmailDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterAccountUserMinimalDto>>;
    public waterAccountsWaterAccountIDInvitingUserInvitingUserIDPost(waterAccountID: number, invitingUserID: number, addUserByEmailDto?: AddUserByEmailDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterAccountUserMinimalDto>>;
    public waterAccountsWaterAccountIDInvitingUserInvitingUserIDPost(waterAccountID: number, invitingUserID: number, addUserByEmailDto?: AddUserByEmailDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDInvitingUserInvitingUserIDPost.');
        }

        if (invitingUserID === null || invitingUserID === undefined) {
            throw new Error('Required parameter invitingUserID was null or undefined when calling waterAccountsWaterAccountIDInvitingUserInvitingUserIDPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<WaterAccountUserMinimalDto>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/inviting-user/${encodeURIComponent(String(invitingUserID))}`,
            addUserByEmailDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param invitingUserID 
     * @param waterAccountUserMinimalDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDInvitingUserInvitingUserIDResendPost(waterAccountID: number, invitingUserID: number, waterAccountUserMinimalDto?: WaterAccountUserMinimalDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public waterAccountsWaterAccountIDInvitingUserInvitingUserIDResendPost(waterAccountID: number, invitingUserID: number, waterAccountUserMinimalDto?: WaterAccountUserMinimalDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public waterAccountsWaterAccountIDInvitingUserInvitingUserIDResendPost(waterAccountID: number, invitingUserID: number, waterAccountUserMinimalDto?: WaterAccountUserMinimalDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public waterAccountsWaterAccountIDInvitingUserInvitingUserIDResendPost(waterAccountID: number, invitingUserID: number, waterAccountUserMinimalDto?: WaterAccountUserMinimalDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDInvitingUserInvitingUserIDResendPost.');
        }

        if (invitingUserID === null || invitingUserID === undefined) {
            throw new Error('Required parameter invitingUserID was null or undefined when calling waterAccountsWaterAccountIDInvitingUserInvitingUserIDResendPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/inviting-user/${encodeURIComponent(String(invitingUserID))}/resend`,
            waterAccountUserMinimalDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param userID 
     * @param waterAccountUserMinimalDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDUserUserIDPut(waterAccountID: number, userID: number, waterAccountUserMinimalDto?: WaterAccountUserMinimalDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public waterAccountsWaterAccountIDUserUserIDPut(waterAccountID: number, userID: number, waterAccountUserMinimalDto?: WaterAccountUserMinimalDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public waterAccountsWaterAccountIDUserUserIDPut(waterAccountID: number, userID: number, waterAccountUserMinimalDto?: WaterAccountUserMinimalDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public waterAccountsWaterAccountIDUserUserIDPut(waterAccountID: number, userID: number, waterAccountUserMinimalDto?: WaterAccountUserMinimalDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDUserUserIDPut.');
        }

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling waterAccountsWaterAccountIDUserUserIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/user/${encodeURIComponent(String(userID))}`,
            waterAccountUserMinimalDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param waterAccountUserID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDUserWaterAccountUserIDDelete(waterAccountID: number, waterAccountUserID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public waterAccountsWaterAccountIDUserWaterAccountUserIDDelete(waterAccountID: number, waterAccountUserID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public waterAccountsWaterAccountIDUserWaterAccountUserIDDelete(waterAccountID: number, waterAccountUserID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public waterAccountsWaterAccountIDUserWaterAccountUserIDDelete(waterAccountID: number, waterAccountUserID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDUserWaterAccountUserIDDelete.');
        }

        if (waterAccountUserID === null || waterAccountUserID === undefined) {
            throw new Error('Required parameter waterAccountUserID was null or undefined when calling waterAccountsWaterAccountIDUserWaterAccountUserIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/user/${encodeURIComponent(String(waterAccountUserID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDUsersGet(waterAccountID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountUserMinimalDto>>;
    public waterAccountsWaterAccountIDUsersGet(waterAccountID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountUserMinimalDto>>>;
    public waterAccountsWaterAccountIDUsersGet(waterAccountID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountUserMinimalDto>>>;
    public waterAccountsWaterAccountIDUsersGet(waterAccountID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDUsersGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountUserMinimalDto>>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
