/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GeographyBoundarySimpleDto } from '../model/geography-boundary-simple-dto';
import { GeographyDto } from '../model/geography-dto';
import { GeographyForAdminEditorsDto } from '../model/geography-for-admin-editors-dto';
import { GeographyMinimalDto } from '../model/geography-minimal-dto';
import { UserDto } from '../model/user-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class GeographyService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesCurrentUserGet(observe?: 'body', reportProgress?: boolean): Observable<Array<GeographyMinimalDto>>;
    public geographiesCurrentUserGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeographyMinimalDto>>>;
    public geographiesCurrentUserGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeographyMinimalDto>>>;
    public geographiesCurrentUserGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GeographyMinimalDto>>(`${this.basePath}/geographies/current-user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDEffectiveYearsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public geographiesGeographyIDEffectiveYearsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public geographiesGeographyIDEffectiveYearsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public geographiesGeographyIDEffectiveYearsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDEffectiveYearsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/effectiveYears`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<GeographyDto>;
    public geographiesGeographyIDGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyDto>>;
    public geographiesGeographyIDGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyDto>>;
    public geographiesGeographyIDGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GeographyDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param geographyForAdminEditorsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDPut(geographyID: number, geographyForAdminEditorsDto?: GeographyForAdminEditorsDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDPut(geographyID: number, geographyForAdminEditorsDto?: GeographyForAdminEditorsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDPut(geographyID: number, geographyForAdminEditorsDto?: GeographyForAdminEditorsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDPut(geographyID: number, geographyForAdminEditorsDto?: GeographyForAdminEditorsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}`,
            geographyForAdminEditorsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDUploadParcelGdbPost(geographyID: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDUploadParcelGdbPost(geographyID: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDUploadParcelGdbPost(geographyID: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDUploadParcelGdbPost(geographyID: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDUploadParcelGdbPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/upload-parcel-gdb`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param userDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWaterManagersPut(geographyID: number, userDto?: Array<UserDto>, observe?: 'body', reportProgress?: boolean): Observable<GeographyDto>;
    public geographiesGeographyIDWaterManagersPut(geographyID: number, userDto?: Array<UserDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyDto>>;
    public geographiesGeographyIDWaterManagersPut(geographyID: number, userDto?: Array<UserDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyDto>>;
    public geographiesGeographyIDWaterManagersPut(geographyID: number, userDto?: Array<UserDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWaterManagersPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<GeographyDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/water-managers`,
            userDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyNameGeographyNameForAdminEditorGet(geographyName: string, observe?: 'body', reportProgress?: boolean): Observable<GeographyForAdminEditorsDto>;
    public geographiesGeographyNameGeographyNameForAdminEditorGet(geographyName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyForAdminEditorsDto>>;
    public geographiesGeographyNameGeographyNameForAdminEditorGet(geographyName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyForAdminEditorsDto>>;
    public geographiesGeographyNameGeographyNameForAdminEditorGet(geographyName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyName === null || geographyName === undefined) {
            throw new Error('Required parameter geographyName was null or undefined when calling geographiesGeographyNameGeographyNameForAdminEditorGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GeographyForAdminEditorsDto>(`${this.basePath}/geographies/geography-name/${encodeURIComponent(String(geographyName))}/for-admin-editor`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyNameGeographyNameMinimalGet(geographyName: string, observe?: 'body', reportProgress?: boolean): Observable<GeographyMinimalDto>;
    public geographiesGeographyNameGeographyNameMinimalGet(geographyName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyMinimalDto>>;
    public geographiesGeographyNameGeographyNameMinimalGet(geographyName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyMinimalDto>>;
    public geographiesGeographyNameGeographyNameMinimalGet(geographyName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyName === null || geographyName === undefined) {
            throw new Error('Required parameter geographyName was null or undefined when calling geographiesGeographyNameGeographyNameMinimalGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GeographyMinimalDto>(`${this.basePath}/geographies/geography-name/${encodeURIComponent(String(geographyName))}/minimal`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<GeographyDto>>;
    public geographiesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeographyDto>>>;
    public geographiesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeographyDto>>>;
    public geographiesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GeographyDto>>(`${this.basePath}/geographies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGsaBoundariesPut(observe?: 'body', reportProgress?: boolean): Observable<Array<GeographyBoundarySimpleDto>>;
    public geographiesGsaBoundariesPut(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeographyBoundarySimpleDto>>>;
    public geographiesGsaBoundariesPut(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeographyBoundarySimpleDto>>>;
    public geographiesGsaBoundariesPut(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<Array<GeographyBoundarySimpleDto>>(`${this.basePath}/geographies/gsa-boundaries`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
