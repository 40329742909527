/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WaterMeasurementSelfReportLineItemUpdateDto } from '././water-measurement-self-report-line-item-update-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class WaterMeasurementSelfReportUpdateDto { 
    LineItems: Array<WaterMeasurementSelfReportLineItemUpdateDto>;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface WaterMeasurementSelfReportUpdateDtoForm { 
    LineItems: FormControl<Array<WaterMeasurementSelfReportLineItemUpdateDto>>;
}

export class WaterMeasurementSelfReportUpdateDtoFormControls { 
    public static LineItems = (value: FormControlState<Array<WaterMeasurementSelfReportLineItemUpdateDto>> | Array<WaterMeasurementSelfReportLineItemUpdateDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<WaterMeasurementSelfReportLineItemUpdateDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
}
