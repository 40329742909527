<div class="full-width-page">
    <div class="page-header centered">
        <h1 class="page-title">
            <span>
                <icon class="page-header-icon" icon="License"></icon>
                License
            </span>
        </h1>
    </div>

    <section class="license grid-12">
        <div class="sub-header">
            <div class="copy copy-2">
                <p>
                    The Groundwater Accounting Platform is developed using open-source software under the
                    <a href="https://www.gnu.org/licenses/agpl-3.0.en.html">GNU Affero General Public License</a> (AGPL). It can be redistributed and/or modified under the terms of
                    AGPL. Source code is available on request. Platform source code copyright &copy; Environmental Science Associates, California Water Data Consortium, and
                    Environmental Defense Fund. Groundwater Evaluation Toolbox copyright &copy; Olsson.
                </p>

                <p>All data is controlled by the participating agencies and will not be shared or utilized without their express consent or as required by law.</p>
            </div>
        </div>
    </section>
</div>
