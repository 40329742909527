import { Component, OnInit } from "@angular/core";
import { ReportingPeriodService } from "src/app/shared/generated/api/reporting-period.service";
import { ReportingPeriodSimpleDto } from "src/app/shared/generated/model/reporting-period-simple-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertService } from "src/app/shared/services/alert.service";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { forkJoin, Observable, switchMap, tap } from "rxjs";
import { GeographyService } from "src/app/shared/generated/api/geography.service";
import { GeographyDto } from "src/app/shared/generated/model/geography-dto";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { UtilityFunctionsService } from "src/app/shared/services/utility-functions.service";
import { FormsModule } from "@angular/forms";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";
import { AlertDisplayComponent } from "../../../shared/components/alert-display/alert-display.component";
import { ModelNameTagComponent } from "../../../shared/components/name-tag/name-tag.component";
import { PageHeaderComponent } from "src/app/shared/components/page-header/page-header.component";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { CurrentGeographyService } from "src/app/shared/services/current-geography.service";
import { GeographyMinimalDto } from "src/app/shared/generated/model/models";
import { routeParams } from "src/app/app.routes";
import { LoadingDirective } from "src/app/shared/directives/loading.directive";

@Component({
    selector: "reporting-period-configure",
    templateUrl: "./reporting-period-configure.component.html",
    styleUrls: ["./reporting-period-configure.component.scss"],
    standalone: true,
    imports: [AsyncPipe, LoadingDirective, PageHeaderComponent, ModelNameTagComponent, AlertDisplayComponent, NgIf, FormsModule, NgFor, RouterLink],
})
export class ReportingPeriodConfigureComponent implements OnInit {
    public geography$: Observable<GeographyMinimalDto>;
    public geographyID: number;
    public isLoading: boolean;

    public reportingPeriod$: Observable<ReportingPeriodSimpleDto>;
    public reportingPeriod: ReportingPeriodSimpleDto;

    public intervals = ["Monthly"];
    public originalReportingPeriod: string;

    public hoverText = "This feature is necessary to the platform user experience and cannot be turned off.";

    constructor(
        private route: ActivatedRoute,
        private reportingPeriodService: ReportingPeriodService,
        private alertService: AlertService,
        private geographyService: GeographyService,
        private currentGeographyService: CurrentGeographyService,
        private utilityFunctionService: UtilityFunctionsService
    ) {}

    ngOnInit(): void {
        this.reportingPeriod$ = this.route.params.pipe(
            tap(() => {
                this.isLoading = true;
            }),
            switchMap((params) => {
                const geographyName = params[routeParams.geographyName];
                return this.geographyService.geographiesGeographyNameGeographyNameMinimalGet(geographyName);
            }),
            tap((geography) => {
                this.alertService.clearAlerts();
                this.currentGeographyService.setCurrentGeography(geography);
                this.geographyID = geography.GeographyID;
            }),
            switchMap((geography) => {
                return this.reportingPeriodService.geographiesGeographyIDReportingPeriodGet(geography.GeographyID);
            }),
            tap((reportingPeriod) => {
                this.reportingPeriod = reportingPeriod;
                this.originalReportingPeriod = JSON.stringify(reportingPeriod);
                this.isLoading = false;
            })
        );
    }

    canExit() {
        return this.originalReportingPeriod == JSON.stringify(this.reportingPeriod);
    }

    saveReportingPeriod() {
        this.reportingPeriodService.geographiesGeographyIDReportingPeriodUpdatePost(this.geographyID, this.reportingPeriod).subscribe((response) => {
            this.reportingPeriod = response;
            this.originalReportingPeriod = JSON.stringify(this.reportingPeriod);
            this.alertService.pushAlert(new Alert("Successfully saved!", AlertContext.Success, true));
        });
    }

    getNumberFromMonth(month: string) {
        return this.utilityFunctionService.getNumberFromMonth(month);
    }
}
