<div *ngIf="parcel$ | async">
    <page-header [templateRight]="templateRight" icon="Parcels" [pageTitle]="parcel.ParcelNumber">
        <ng-template #templateRight>
            <ng-container *ngIf="parcel.WaterAccount">
                <a [routerLink]="['water-accounts', parcel.WaterAccountID]">
                    <water-account-title [waterAccountName]="parcel.WaterAccount.WaterAccountName"
                        [waterAccountNumber]="parcel.WaterAccount.WaterAccountNumber.toString()"></water-account-title>
                </a>
            </ng-container>
        </ng-template>
    </page-header>

    <div class="page-body" [loadingSpinner]="{ isLoading: isLoading, loadingHeight: 500 }">
        <div class="card management-actions">
            <div class="card-header">
                <h3 class="water-account-title | section-title">
                    Management Actions
                    <name-tag name="Admin" color="#000"></name-tag>
                </h3>
            </div>
            <div class="card-body flex-start">
                <a href="javascript:void(0);" (click)="updateOwnershipInfo()">
                    <icon icon="Users"></icon>
                    Update Ownership Info
                </a>
                <a href="javascript:void(0);" (click)="updateWaterAccount()">
                    <icon icon="WaterAccounts"></icon>
                    Update Water Account
                </a>
                <a href="javascript:void(0);" (click)="editZoneAssignments()">
                    <icon icon="Zones"></icon>
                    Edit Zone Assignments
                </a>
                <a href="javascript:void(0);" (click)="modifyParcelStatus()">
                    <icon icon="Parcels"></icon>
                    Modify Parcel Status
                </a>
                <a href="javascript:void(0);" (click)="editAcres()">
                    <icon icon="Parcels"></icon>
                    Edit Acres
                </a>
            </div>
        </div>
        <div class="card custom-attributes g-col-12" id="custom_attributes_anchor" *ngIf="parcelCustomAttributes$ | async as parcelCustomAttributes">
            <div class="card-header flex-between">
                <h3 class="card-title">
                    Attributes
                    <name-tag name="Admin" color="#000"></name-tag>
                </h3>

                <a *ngIf="parcelCustomAttributes.CustomAttributes" class="btn btn-sm btn-secondary" routerLink="edit-attributes">
                    <span class="fas fa-edit"></span>
                    Edit Attributes
                </a>
            </div>
            <div class="card-body">
                <key-value-pair-list *ngIf="parcelCustomAttributes.CustomAttributes; else noCustomAttributesConfigured">
                    <key-value-pair [horizontal]="true" [copyValueToClipboard]="customAttribute.value !== ''"
                        *ngFor="let customAttribute of parcelCustomAttributes.CustomAttributes | keyvalue">
                        <ng-container key>{{ customAttribute.key }}</ng-container>
                        <ng-container keyValue>
                            {{ customAttribute.value }}
                            <em *ngIf="!customAttribute.value" class="text-muted">Not Available</em>
                        </ng-container>
                    </key-value-pair>
                </key-value-pair-list>
                <ng-template #noCustomAttributesConfigured>
                    <div class="copy copy-3 no-custom-attributes">
                        No custom attributes have been configured for Parcels in this geography.
                        <br />
                        Contact an administrator to learn more about this feature.
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="parcel-detail-manage-card card">
            <div class="card-header">
                <h3 class="card-title">
                    Wells
                    <name-tag name="Admin" color="#000"></name-tag>
                </h3>
            </div>
            <div class="card-body">
                <div class="grid-12">
                    <div class="g-col-3">
                        <key-value-pair-list>
                            <key-value-pair>
                                <ng-container key>Wells on Parcel</ng-container>
                                <ng-container keyValue>
                                    <ng-container *ngIf="parcel.WellsOnParcel && parcel.WellsOnParcel.length > 0; else noWellsOnParcel">
                                        <div *ngFor="let well of parcel.WellsOnParcel">
                                            <a [routerLink]="['/manage', parcel.GeographyName.toLowerCase(), 'wells', well.WellID]"> {{ well.WellName }} ({{
                                                well.WellID }}) </a>
                                        </div>
                                    </ng-container>
                                    <ng-template #noWellsOnParcel>
                                        <em>Not Available</em>
                                    </ng-template>
                                </ng-container>
                            </key-value-pair>
                        </key-value-pair-list>
                    </div>
                    <div class="g-col-3">
                        <key-value-pair-list>
                            <key-value-pair>
                                <ng-container key>Irrigated By Wells</ng-container>
                                <ng-container keyValue>
                                    <ng-container *ngIf="parcel.IrrigatedByWells && parcel.IrrigatedByWells.length > 0; else noIrrigatedByWells">
                                        <div *ngFor="let well of parcel.IrrigatedByWells">
                                            <a [routerLink]="['/manage', parcel.GeographyName.toLowerCase(), 'wells', well.WellID]"> {{ well.WellName }} ({{
                                                well.WellID }}) </a>
                                        </div>
                                    </ng-container>
                                    <ng-template #noIrrigatedByWells>
                                        <em>Not Available</em>
                                    </ng-template>
                                </ng-container>
                            </key-value-pair>
                        </key-value-pair-list>
                    </div>
                    <div class="g-col-6" *ngIf="wellLocations$ | async as wellLocations">
                        <qanat-map (onMapLoad)="handleMapReady($event)" mapHeight="500px">
                            <ng-container *ngIf="mapIsReady">
                                <highlighted-parcels-layer [displayOnLoad]="true" [map]="map" controlTitle="Irrigated Parcel(s)"
                                    [geographyID]="parcel.GeographyID" [highlightedParcelIDs]="[parcel.ParcelID]"
                                    [layerControl]="layerControl"></highlighted-parcels-layer>
                                <wells-layer [wells]="wellLocations" [displayOnLoad]="true" [map]="map" [layerControl]="layerControl"></wells-layer>
                            </ng-container>
                        </qanat-map>
                    </div>
                </div>
            </div>
        </div>
        <div class="card ownership-history" id="ownership_history_anchor" *ngIf="parcel$ | async as parcel">
            <div class="card-header flex-between">
                <h3 class="card-title">
                    Parcel History
                    <name-tag name="Admin" color="#000"></name-tag>
                </h3>
                <button class="btn btn-secondary" (click)="updateOwnershipInfo()">Update Ownership Info</button>
            </div>

            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Effective Year</th>
                                <th>Water Account</th>
                                <th>Owner Name</th>
                                <th>Owner Address</th>
                                <th>Status</th>
                                <th>Updated</th>
                                <th>Updated By</th>
                                <th>Source</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let parcelHistory of parcelHistories$ | async">
                                <td>
                                    {{ parcelHistory.EffectiveYear }}
                                </td>
                                <td>
                                    <a *ngIf="parcelHistory.WaterAccount" class="no-underline"
                                        routerLink="../../../water-accounts/{{ parcelHistory.WaterAccountID }}">
                                        <water-account-title [waterAccountName]="parcelHistory.WaterAccount?.WaterAccountName"
                                            [waterAccountNumber]="parcelHistory.WaterAccount?.WaterAccountNumber.toString()"></water-account-title>
                                    </a>
                                </td>
                                <td>
                                    {{ parcelHistory.OwnerName }}
                                </td>
                                <td>
                                    {{ parcelHistory.OwnerAddress }}
                                </td>
                                <td>
                                    {{ parcelHistory.ParcelStatus.ParcelStatusDisplayName }}
                                </td>
                                <td>
                                    {{ parcelHistory.UpdateDate | date : "short" }}
                                </td>
                                <td>
                                    {{ parcelHistory.UpdateUserFullName }}
                                </td>
                                <td>
                                    {{ parcelHistory.IsManualOverride ? "Manual Entry" : "GDB Upload" }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card ownership-history" id="water_account_history_anchor">
            <div class="card-header flex-between">
                <h3 class="card-title">
                    Water Accounts
                    <name-tag name="Admin" color="#000"></name-tag>
                </h3>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="width: 25%">Effective Year</th>
                                <th style="width: 25%">End Year</th>
                                <th style="width: 50%">Water Account</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="waterAccountParcels$ | async as waterAccountParcels">
                                <ng-container *ngIf="waterAccountParcels.length; else noHistory">
                                    <tr *ngFor="let waterAccountParcel of waterAccountParcels">
                                        <td>
                                            {{ waterAccountParcel.EffectiveYear }}
                                        </td>
                                        <td>
                                            {{ waterAccountParcel.EndYear ?? "Current" }}
                                        </td>
                                        <td>
                                            <a class="no-underline" routerLink="../../../water-accounts/{{ waterAccountParcel.WaterAccountID }}">
                                                <water-account-title [waterAccountName]="waterAccountParcel.WaterAccount?.WaterAccountName"
                                                    [waterAccountNumber]="waterAccountParcel.WaterAccount?.WaterAccountNumber.toString()"></water-account-title>
                                            </a>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #noHistory>
                                    <td colspan="3">
                                        <em> This parcel does not have any water account history. </em>
                                    </td>
                                </ng-template>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>