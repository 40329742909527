<ng-container *ngIf="wellRegistration$ | async as wellRegistration; else isLoadingTemplate">
    <div class="full-height-wrapper">
        <page-header icon="Wells" [templateRight]="templateRight" [templateAbove]="templateAbove" [pageTitle]="wellRegistration.WellName ?? 'Unnamed Well'">
            <ng-template #templateRight>
                <a
                    *ngIf="showUpdateButton"
                    [routerLink]="['/well-registry/', wellRegistration.Geography.GeographyName.toLowerCase(), 'well', wellRegistration.WellRegistrationID, 'edit']"
                    class="btn btn-primary">
                    Update Well
                </a>
            </ng-template>
            <ng-template #templateAbove>
                <div class="back">
                    <a *ngIf="wellRegistration.WellID; else backToAllRegistrations" [routerLink]="['/wells', wellRegistration.WellID]" class="back__link">Back to Well Detail</a>

                    <ng-template #backToAllRegistrations>
                        <a *ngIf="wellRegistration.WellID; else backToAllRegistrations" routerLink=".." class="back__link">Back to All Well Registrations</a>
                    </ng-template>
                </div>
            </ng-template>
        </page-header>

        <div class="page-body">
            <app-alert-display></app-alert-display>

            <!--LOCATION-->
            <div class="grid-12 location">
                <ng-container *ngIf="wellRegistration">
                    <div class="card g-col-4">
                        <div class="card-header">
                            <h3 class="card-title">Location</h3>
                        </div>
                        <div class="card-body">
                            <dl class="kv-pair">
                                <dt class="key">Latitude</dt>
                                <dd class="value">
                                    {{ wellRegistration.Latitude | number : "1.2-2" }}
                                </dd>

                                <dt class="key">Longitude</dt>
                                <dd class="value">
                                    {{ wellRegistration.Longitude | number : "1.2-2" }}
                                </dd>

                                <ng-container *ngIf="wellRegistration.Parcel">
                                    <dt class="key">Parcel APN</dt>
                                    <dd class="value">
                                        {{ wellRegistration.Parcel.ParcelNumber }}
                                    </dd>
                                </ng-container>

                                <ng-container *ngIf="irrigatedParcelNumbers?.length > 0">
                                    <dt class="key">Irrigated Parcel APNs</dt>
                                    <dd class="value">
                                        <div *ngFor="let parcelNumber of irrigatedParcelNumbers">
                                            {{ parcelNumber }}
                                        </div>
                                    </dd>
                                </ng-container>
                            </dl>
                        </div>
                    </div>
                </ng-container>

                <div class="g-col-8 map">
                    <qanat-map (onMapLoad)="handleMapReady($event)" mapHeight="500px">
                        <ng-container *ngIf="mapIsReady">
                            <highlighted-parcels-layer
                                [displayOnLoad]="true"
                                [map]="map"
                                controlTitle="Irrigated Parcel(s)"
                                [geographyID]="wellRegistration.Geography.GeographyID"
                                [highlightedParcelIDs]="irrigatedParcelIDs"
                                [layerControl]="layerControl"></highlighted-parcels-layer>
                            <well-registrations-layer
                                [wellRegistrations]="wellRegistrationAsArray"
                                [displayOnLoad]="true"
                                [map]="map"
                                [layerControl]="layerControl"></well-registrations-layer>
                        </ng-container>
                    </qanat-map>
                </div>
            </div>

            <hr />

            <ng-container *ngIf="wellRegistration">
                <!-- CONTACTS -->
                <div class="section-header pb-3">
                    <h2 class="section-title">Contacts</h2>
                </div>

                <div class="grid-12">
                    <div class="card g-col-6">
                        <div class="card-header">
                            <h3 class="card-title">Landowner</h3>
                        </div>

                        <div class="card-body">
                            <dl class="kv-pair" *ngIf="wellRegistration.LandownerContact">
                                <dt class="key">Contact Name</dt>
                                <dd class="value">
                                    {{ wellRegistration.LandownerContact.ContactName }}
                                </dd>

                                <dt class="key">Business Name</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.LandownerContact.BusinessName">
                                        {{ wellRegistration.LandownerContact.BusinessName }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Street Address</dt>
                                <dd class="value">
                                    {{ wellRegistration.LandownerContact.StreetAddress }}
                                </dd>

                                <dt class="key">City</dt>
                                <dd class="value">{{ wellRegistration.LandownerContact.City }}</dd>

                                <dt class="key">State</dt>
                                <dd class="value">
                                    {{ wellRegistration.LandownerContact.StateName }}
                                </dd>

                                <dt class="key">Zip Code</dt>
                                <dd class="value">
                                    {{ wellRegistration.LandownerContact.ZipCode }}
                                </dd>

                                <dt class="key">Phone</dt>
                                <dd class="value">{{ wellRegistration.LandownerContact.Phone }}</dd>

                                <dt class="key">Email</dt>
                                <dd class="value">{{ wellRegistration.LandownerContact.Email }}</dd>
                            </dl>
                        </div>
                    </div>

                    <div class="card g-col-6">
                        <div class="card-header">
                            <h3 class="card-title">Owner Operator</h3>
                        </div>

                        <div class="card-body card-body__operator">
                            <dl class="kv-pair" *ngIf="wellRegistration.OwnerOperatorContact">
                                <dt class="key">Contact Name</dt>
                                <dd class="value">
                                    {{ wellRegistration.OwnerOperatorContact.ContactName }}
                                </dd>

                                <dt class="key">Business Name</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.OwnerOperatorContact.BusinessName">
                                        {{ wellRegistration.OwnerOperatorContact.BusinessName }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Street Address</dt>
                                <dd class="value">
                                    {{ wellRegistration.OwnerOperatorContact.StreetAddress }}
                                </dd>

                                <dt class="key">City</dt>
                                <dd class="value">
                                    {{ wellRegistration.OwnerOperatorContact.City }}
                                </dd>

                                <dt class="key">State</dt>
                                <dd class="value">
                                    {{ wellRegistration.OwnerOperatorContact.StateName }}
                                </dd>

                                <dt class="key">Zip Code</dt>
                                <dd class="value">
                                    {{ wellRegistration.OwnerOperatorContact.ZipCode }}
                                </dd>

                                <dt class="key">Phone</dt>
                                <dd class="value">
                                    {{ wellRegistration.OwnerOperatorContact.Phone }}
                                </dd>

                                <dt class="key">Email</dt>
                                <dd class="value">
                                    {{ wellRegistration.OwnerOperatorContact.Email }}
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>

                <hr />

                <!-- WELL DETAILS -->
                <div class="section-header pb-3">
                    <h2 class="section-title">Well Details</h2>
                </div>

                <div class="grid-12 well-registration-details">
                    <div class="card g-col-4 basic-info">
                        <div class="card-header">
                            <h3 class="card-title">Basic Info</h3>
                        </div>

                        <div class="card-body">
                            <dl class="kv-pair" *ngIf="wellRegistration?.WellRegistrationMetadatum">
                                <dt class="key">Owner's Well Name/No.</dt>
                                <dd class="value">{{ wellRegistration.WellName }}</dd>

                                <dt class="key">State Well No.</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.StateWellNumber">
                                        {{ wellRegistration.WellRegistrationMetadatum.StateWellNumber }}
                                    </ng-container>
                                </dd>

                                <dt class="key">State WCR No.</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.StateWellCompletionNumber">
                                        {{ wellRegistration.WellRegistrationMetadatum.StateWellCompletionNumber }}
                                    </ng-container>
                                </dd>

                                <dt class="key">County Well Permit</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.StateWellCompletionNumber">
                                        {{ wellRegistration.WellRegistrationMetadatum.CountyWellPermit }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Date Drilled</dt>
                                <dd class="value">
                                    {{ wellRegistration.DateDrilled | date : "shortDate" : "utc" }}
                                </dd>
                            </dl>
                        </div>
                    </div>

                    <div class="card g-col-4 technical-info">
                        <div class="card-header">
                            <h3 class="card-title">Technical Info</h3>
                        </div>

                        <div class="card-body">
                            <dl class="kv-pair" *ngIf="wellRegistration?.WellRegistrationMetadatum">
                                <dt class="key">Well Depth (ft.)</dt>
                                <dd class="value">
                                    {{ wellRegistration.WellRegistrationMetadatum.WellDepth }}
                                </dd>

                                <dt class="key">Casing Diameter (in.)</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.CasingDiameter">
                                        {{ wellRegistration.WellRegistrationMetadatum.CasingDiameter }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Top of Perforations</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.TopOfPerforations">
                                        {{ wellRegistration.WellRegistrationMetadatum.TopOfPerforations }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Bottom of Perforations</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.BottomOfPerforations">
                                        {{ wellRegistration.WellRegistrationMetadatum.BottomOfPerforations }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Serial No. of Water Meter</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.SerialNumberOfWaterMeter">
                                        {{ wellRegistration.WellRegistrationMetadatum.SerialNumberOfWaterMeter }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Manufacturer of Water Meter</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.ManufacturerOfWaterMeter">
                                        {{ wellRegistration.WellRegistrationMetadatum.ManufacturerOfWaterMeter }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Electric Meter Number</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.ElectricMeterNumber">
                                        {{ wellRegistration.WellRegistrationMetadatum.ElectricMeterNumber }}
                                    </ng-container>
                                </dd>
                            </dl>
                        </div>
                    </div>

                    <div class="card g-col-4 water-uses">
                        <div class="card-header">
                            <h3 class="card-title">Water Uses</h3>
                        </div>

                        <div class="card-body">
                            <dl class="kv-pair" *ngIf="wellRegistration?.WellRegistrationWaterUses">
                                <ng-container *ngFor="let wellRegistrationWaterUse of wellRegistration.WellRegistrationWaterUses">
                                    <dt class="key">
                                        {{ wellRegistrationWaterUse.WellRegistrationWaterUseTypeDisplayName }}
                                    </dt>
                                    <dd class="value">
                                        {{ wellRegistrationWaterUse.WellRegistrationWaterUseDescription }}
                                    </dd>
                                </ng-container>
                            </dl>
                        </div>
                    </div>
                </div>

                <hr />

                <!-- PUMP DETAILS -->
                <div class="section-header pb-3">
                    <h2 class="section-title">Pump Details</h2>
                </div>

                <div class="grid-12">
                    <div class="card g-col-6">
                        <div class="card-header">
                            <h3 class="card-title">Basic Info</h3>
                        </div>

                        <div class="card-body">
                            <dl class="kv-pair" *ngIf="wellRegistration?.WellRegistrationMetadatum">
                                <dt class="key">Pump Discharge Diameter (in.)</dt>
                                <dd class="value">
                                    {{ wellRegistration.WellRegistrationMetadatum.PumpDischargeDiameter }}
                                </dd>

                                <dt class="key">Motor/Engine (hp)</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.MotorHorsePower">
                                        {{ wellRegistration.WellRegistrationMetadatum.MotorHorsePower }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Fuel Type</dt>
                                <dd class="value">
                                    {{ wellRegistrationFuelType?.Label }}
                                </dd>

                                <dt class="key">Fuel Type Description</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistrationFuelType?.Value === FuelTypeEnum.Other">
                                        {{ wellRegistration.WellRegistrationMetadatum?.FuelOther }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Maximum Flow</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.MaximumFlow">
                                        {{ wellRegistration.WellRegistrationMetadatum.MaximumFlow }}
                                        <span *ngIf="wellRegistration.WellRegistrationMetadatum.IsEstimatedMax as isEstimatedMax">
                                            ({{ isEstimatedMax ? "estimated" : "measured" }})
                                        </span>
                                    </ng-container>
                                </dd>

                                <dt class="key">Maximum Flow</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.TypicalPumpFlow">
                                        {{ wellRegistration.WellRegistrationMetadatum.MaximumFlow }}
                                        <span *ngIf="wellRegistration.WellRegistrationMetadatum.IsEstimatedTypical as isEstimatedTypical">
                                            ({{ isEstimatedTypical ? "estimated" : "measured" }})
                                        </span>
                                    </ng-container>
                                </dd>
                            </dl>
                        </div>
                    </div>

                    <div class="card g-col-6">
                        <div class="card-header">
                            <h3 class="card-title">Pump Test (Most Recent)</h3>
                        </div>

                        <div class="card-body">
                            <dl class="kv-pair" *ngIf="wellRegistration?.WellRegistrationMetadatum">
                                <dt class="key">Conducted By</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.PumpTestBy">
                                        {{ wellRegistration.WellRegistrationMetadatum.PumpTestBy }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Performed Test Date</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.PumpTestDatePerformed">
                                        {{ wellRegistration.WellRegistrationMetadatum.PumpTestDatePerformed | date : "shortDate" : "utc" }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Pump Manufacturer</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.PumpManufacturer">
                                        {{ wellRegistration.WellRegistrationMetadatum.PumpManufacturer }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Yield (gpm)</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.PumpYield">
                                        {{ wellRegistration.WellRegistrationMetadatum.PumpYield }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Static Level (ft.)</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.PumpStaticLevel">
                                        {{ wellRegistration.WellRegistrationMetadatum.PumpStaticLevel }}
                                    </ng-container>
                                </dd>

                                <dt class="key">Pumping Level (ft.)</dt>
                                <dd class="value">
                                    <ng-container *ngIf="wellRegistration.WellRegistrationMetadatum.PumpingLevel">
                                        {{ wellRegistration.WellRegistrationMetadatum.PumpingLevel }}
                                    </ng-container>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>

                <hr />

                <!-- ATTACHMENTS -->
                <div class="section-header pb-3">
                    <h2 class="section-title">Attachments</h2>
                </div>

                <div class="grid-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Uploaded Files</h3>
                        </div>

                        <div class="card-body card-body__attachments">
                            <ng-container *ngIf="wellRegistration.WellRegistrationFileResources?.length > 0; else noAttachments">
                                <div *ngFor="let wellRegistrationFileResource of wellRegistration.WellRegistrationFileResources; let index = index; let last = last">
                                    <div class="grid-12 pb-3">
                                        <div class="g-col-8 attachment-data">
                                            <div class="pb-2">
                                                <a style="cursor: pointer" (click)="openFileResourceLink(wellRegistrationFileResource.FileResource)">
                                                    <b>
                                                        {{ wellRegistrationFileResource.FileResource.OriginalBaseFilename }}
                                                    </b>
                                                </a>
                                            </div>
                                            <div class="pb-2">
                                                {{ wellRegistrationFileResource.FileDescription }}
                                            </div>
                                            <div>
                                                <em>
                                                    Uploaded
                                                    {{ wellRegistrationFileResource.FileResource.CreateDate | date : "short" }}
                                                </em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-template #noAttachments>
                                <div class="no-attachments copy copy-3">
                                    <p>No attachments uploaded.</p>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-template #isLoadingTemplate>
    <aside class="sidebar" [loadingSpinner]="{ isLoading: true }"></aside>
    <main class="main" [loadingSpinner]="{ isLoading: true }"></main>
</ng-template>
