<div [loadingSpinner]="{ isLoading: isLoadingSubmit, loadingHeight: 500 }">
    <ng-container *ngIf="getAction$ | async as getAction">
        <page-header
            [preTitle]="getAction.Scenario.ScenarioName"
            [icon]="getAction.Scenario.ScenarioID == ScenarioEnum.AddaWell ? 'Wells' : 'WaterDropFilled'"
            [templateTitleAppend]="templateTitleAppend"
            [pageTitle]="getAction.RunName">
            <ng-template #templateTitleAppend>
                <name-tag [name]="getAction.Model.ModelShortName"></name-tag>
            </ng-template>
        </page-header>

        <div class="page-body">
            <app-alert-display></app-alert-display>
            <div class="flex-between mb-4">
                <div class="flex">
                    <get-action-status-bar [getAction]="getAction"></get-action-status-bar>
                    <ng-container *ngIf="getAction.GETActionStatus.GETActionStatusID != GETActionStatusEnum.Complete && currentUser$ | async as currentUser">
                        <a href="javascript:void(0);" (click)="checkStatus()" *withFlag="{ currentUser: currentUser, flag: FlagEnum.IsSystemAdmin }">
                            <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner mr-1"></span>
                            <i class="fa fa-refresh"></i>
                            Refresh
                        </a>
                    </ng-container>
                </div>
                <a
                    class="btn btn-secondary-outline btn-rounded"
                    [class.disabled]="isLoadingSubmit"
                    (click)="downloadOutputJson()"
                    *ngIf="getAction.GETActionStatus.GETActionStatusID == GETActionStatusEnum.Complete">
                    <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner mr-1"></span>
                    Download JSON Results
                </a>
            </div>

            <ng-container *ngIf="getAction.GETActionStatus.GETActionStatusID == GETActionStatusEnum.Complete; else getActionIncomplete">
                <time-series-output *ngIf="getActionResult$ | async as output" [getActionResult]="output" [getAction]="getAction"></time-series-output>
            </ng-container>
        </div>

        <ng-template #getActionIncomplete>
            <div class="alert alert-info">
                <div class="alert-content">
                    <i class="fa fa-info"></i>
                    Scenario run has not yet completed.
                </div>
            </div>
            <fresca-button (click)="checkStatus()" [disabled]="isLoadingSubmit">
                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner mr-1"></span>
                <span>Check Status</span>
            </fresca-button>
        </ng-template>
    </ng-container>
</div>
