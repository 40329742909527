/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class WaterMeasurementSelfReportLineItemUpdateDto { 
    ParcelID: number;
    IrrigationMethodID: number;
    JanuaryOverrideValueInAcreFeet?: number;
    FebruaryOverrideValueInAcreFeet?: number;
    MarchOverrideValueInAcreFeet?: number;
    AprilOverrideValueInAcreFeet?: number;
    MayOverrideValueInAcreFeet?: number;
    JuneOverrideValueInAcreFeet?: number;
    JulyOverrideValueInAcreFeet?: number;
    AugustOverrideValueInAcreFeet?: number;
    SeptemberOverrideValueInAcreFeet?: number;
    OctoberOverrideValueInAcreFeet?: number;
    NovemberOverrideValueInAcreFeet?: number;
    DecemberOverrideValueInAcreFeet?: number;
    readonly HasAnyOverrideValue?: boolean;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface WaterMeasurementSelfReportLineItemUpdateDtoForm { 
    ParcelID: FormControl<number>;
    IrrigationMethodID: FormControl<number>;
    JanuaryOverrideValueInAcreFeet?: FormControl<number>;
    FebruaryOverrideValueInAcreFeet?: FormControl<number>;
    MarchOverrideValueInAcreFeet?: FormControl<number>;
    AprilOverrideValueInAcreFeet?: FormControl<number>;
    MayOverrideValueInAcreFeet?: FormControl<number>;
    JuneOverrideValueInAcreFeet?: FormControl<number>;
    JulyOverrideValueInAcreFeet?: FormControl<number>;
    AugustOverrideValueInAcreFeet?: FormControl<number>;
    SeptemberOverrideValueInAcreFeet?: FormControl<number>;
    OctoberOverrideValueInAcreFeet?: FormControl<number>;
    NovemberOverrideValueInAcreFeet?: FormControl<number>;
    DecemberOverrideValueInAcreFeet?: FormControl<number>;
    HasAnyOverrideValue?: FormControl<boolean>;
}

export class WaterMeasurementSelfReportLineItemUpdateDtoFormControls { 
    public static ParcelID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static IrrigationMethodID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static JanuaryOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FebruaryOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static MarchOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static AprilOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static MayOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static JuneOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static JulyOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static AugustOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SeptemberOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static OctoberOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static NovemberOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DecemberOverrideValueInAcreFeet = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static HasAnyOverrideValue = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
