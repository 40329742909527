<div class="full-width-page">
    <div class="page-header centered">
        <h1 class="page-title">
            <span>
                <icon class="page-header-icon" icon="Contact"></icon>
                Contact
            </span>
        </h1>
    </div>

    <section class="featured grid-12">
        <div class="featured__main g-col-7">
            <div class="feature feature__block">
                <div class="feature__photo">
                    <img src="assets/main/images/contact-hero.jpg" alt="Platform workshop" />
                </div>

                <div class="feature__info">
                    <h3 class="feature__headline">Get in Touch</h3>

                    <div class="feature__copy copy copy-3">
                        <p>
                            Interested in bringing the Groundwater Accounting Platform to your community and growers? Want access to a demo instance of the platform, to meet with
                            us for a 1-on-1 demo, or simply want more information?
                        </p>
                    </div>

                    <a href="mailto:info@groundwateraccounting.org" class="feature__link btn btn-primary btn-sm">Email us</a>
                </div>
            </div>
        </div>

        <div class="featured__sidebar g-col-5">
            <div class="feature">
                <div class="feature__info team">
                    <h3 class="feature__headline">Our Team</h3>

                    <div class="feature__copy copy copy-3">
                        <p>
                            Get to know and connect with our project leaders who are working with growers, local officials, and statewide funders to continue to build the
                            Groundwater Accounting Platform
                        </p>
                    </div>

                    <div class="people-list">
                        <div class="people">
                            <div class="people__photo">
                                <img src="assets/main/images/hannah-ake.jpg" alt="Hannah Ake Photo" />
                            </div>

                            <div class="people__info">
                                <h4 class="people__name">
                                    <span class="people__name-label">Hannah Ake</span>
                                    <span class="people__title">California Platform Coordination & Outreach Lead</span>

                                    <a href="https://www.linkedin.com/in/hannah-ake-b9b15733/" class="people__linkedin" target="blank"
                                        ><img src="assets/main/images/linkedin.svg" alt="LinkedIn"
                                    /></a>
                                </h4>

                                <h5 class="people__org">
                                    <a class="people__org-link" href="https://cawaterdata.org/" target="blank">
                                        <div class="people__org-icon">
                                            <img src="assets/main/images/cwdc-icon.png" alt="CWDC logo" />
                                        </div>

                                        <span class="people__org-label">California Water Data Consortium</span>
                                    </a>
                                </h5>
                            </div>
                        </div>

                        <div class="people">
                            <div class="people__photo">
                                <img src="assets/main/images/noa-bruhis.jpg" alt="Noa Bruhis Photo" />
                            </div>

                            <div class="people__info">
                                <h4 class="people__name">
                                    <span class="people__name-label">Noa Bruhis</span>
                                    <span class="people__title">US Platform Coordination & Outreach Lead</span>

                                    <a href="https://www.linkedin.com/in/noa-bruhis-8a73b64b/" class="people__linkedin" target="blank"
                                        ><img src="assets/main/images/linkedin.svg" alt="LinkedIn"
                                    /></a>
                                </h4>

                                <h5 class="people__org">
                                    <a class="people__org-link" href="https://www.edf.org/" target="blank">
                                        <div class="people__org-icon">
                                            <img src="assets/main/images/edf-icon.png" alt="CWDC logo" />
                                        </div>

                                        <span class="people__org-label">Environmental Defense Fund</span>
                                    </a>
                                </h5>
                            </div>
                        </div>

                        <div class="people">
                            <div class="people__photo">
                                <img src="assets/main/images/john-burns.jpg" alt="John Burns Photo" />
                            </div>

                            <div class="people__info">
                                <h4 class="people__name">
                                    <span class="people__name-label">John Henry Burns</span>
                                    <span class="people__title">Platform Development Lead</span>

                                    <a href="https://www.linkedin.com/in/johnhenryburns/" class="people__linkedin" target="blank"
                                        ><img src="assets/main/images/linkedin.svg" alt="LinkedIn"
                                    /></a>
                                </h4>

                                <h5 class="people__org">
                                    <a class="people__org-link" href="https://esassoc.com/" target="blank">
                                        <div class="people__org-icon">
                                            <img src="assets/main/home/esa.png" alt="ESA logo" />
                                        </div>

                                        <span class="people__org-label">Environmental Science Associates</span>
                                    </a>
                                </h5>
                            </div>
                        </div>

                        <div class="people">
                            <div class="people__photo">
                                <img src="assets/main/images/jim-schneider.jpg" alt="Jim SchneiderPhoto" />
                            </div>

                            <div class="people__info">
                                <h4 class="people__name">
                                    <span class="people__name-label">Jim Schneider</span>
                                    <span class="people__title">Scenario Planning Lead</span>
                                </h4>

                                <h5 class="people__org">
                                    <a class="people__org-link" href="https://www.olsson.com/" target="blank">
                                        <div class="people__org-icon">
                                            <img src="assets/main/images/olsson-icon.png" alt="Olsson logo" />
                                        </div>

                                        <span class="people__org-label">Olssson</span>
                                    </a>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="resources grid-12 section-spacing-top">
        <h2 class="divider-header g-col-12">About the Platform</h2>

        <div class="video-thumbnail g-col-4">
            <a href="https://www.youtube.com/watch?v=AeUv3yiYppk" class="video-thumbnail__link" target="blank">
                <img class="video-thumbnail__btn" src="assets/main/images/play-btn.svg" alt="Play Button" />
                <img class="video-thumbnail__cover" src="assets/main/images/contact-video-data-driven.jpg" alt="User at a computer using the Platform" />
                <span class="video-thumbnail__caption">A Data-Driven Solution for California's Water Challenges</span>
            </a>
        </div>

        <div class="video-thumbnail g-col-4">
            <a href="https://www.youtube.com/watch?v=qEZSYIN7UWk" class="video-thumbnail__link" target="blank">
                <img class="video-thumbnail__btn" src="assets/main/images/play-btn.svg" alt="Play Button" />
                <img class="video-thumbnail__cover" src="assets/main/images/contact-video-get.jpg" alt="Jim Schneider explaining the value of GET" />
                <span class="video-thumbnail__caption">Putting Groundwater Models to Work with GET</span>
            </a>
        </div>

        <div class="rich-link g-col-4">
            <div class="rich-link-header">
                <icon class="page-header-icon" icon="Guide"></icon>
                <h3>Guide for Water Managers</h3>
            </div>

            <div class="rich-link-body copy copy-3">
                <p>Explore the platform as a GSA water manager, looking at the configuration and management tools.</p>
            </div>

            <div class="rich-link-footer">
                <a [routerLink]="['/manager-guide']" class="btn btn-primary-outline btn-sm">Explore</a>
            </div>
        </div>
    </section>
</div>
