<page-header icon="ActivityCenter" [customRichTextTypeID]="customRichTextTypeID"></page-header>

<div class="page-body">
    <app-alert-display></app-alert-display>

    <div class="grid-12 activity-options">
        <ng-container *ngIf="geography$ | async as geography">
            <div class="g-col-6 account-suggestions">
                <h2 class="section-title">Water Account Budgets</h2>
                <div class="copy copy-3 pt-1 pb-2">
                    <p>View detailed reports of water use for each water account.</p>
                </div>

                <a class="btn btn-primary" [routerLink]="['..', 'water-account-budgets-report']">Water Account Budget</a>
            </div>
            <div class="g-col-6 update-parcels">
                <h2 class="section-title">Geography Statistics</h2>
                <div class="copy copy-3 pt-1 pb-2">
                    <p>View high level summary statistics.</p>
                </div>
                <a class="btn btn-primary" [routerLink]="['..', 'statistics']">Geography Statistics</a>
            </div>
            <div class="g-col-6" *ngIf="geography.AllowWaterMeasurementSelfReporting">
                <h2 class="section-title">Review Self Reports</h2>
                <div class="copy copy-3 pt-1 pb-2">
                    <p>Review and approve water measurement self reports.</p>
                </div>
                <a class="btn btn-primary" [routerLink]="['/review-self-reports', geography.GeographyName.toLowerCase()]">Water Measurement Self Reporting</a>
            </div>
        </ng-container>
    </div>
</div>
