/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BoundingBoxDto } from '../model/bounding-box-dto';
import { ParcelAcreUpdateDto } from '../model/parcel-acre-update-dto';
import { ParcelDetailDto } from '../model/parcel-detail-dto';
import { ParcelDisplayDto } from '../model/parcel-display-dto';
import { ParcelHistoryDto } from '../model/parcel-history-dto';
import { ParcelMinimalDto } from '../model/parcel-minimal-dto';
import { ParcelPopupDto } from '../model/parcel-popup-dto';
import { ParcelSupplyDetailDto } from '../model/parcel-supply-detail-dto';
import { ParcelUpdateOwnershipRequestDto } from '../model/parcel-update-ownership-request-dto';
import { ParcelZoneAssignmentFormDto } from '../model/parcel-zone-assignment-form-dto';
import { WaterAccountParcelDto } from '../model/water-account-parcel-dto';
import { WellLocationDto } from '../model/well-location-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class ParcelService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsBoundingBoxPost(requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<BoundingBoxDto>;
    public parcelsBoundingBoxPost(requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BoundingBoxDto>>;
    public parcelsBoundingBoxPost(requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BoundingBoxDto>>;
    public parcelsBoundingBoxPost(requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BoundingBoxDto>(`${this.basePath}/parcels/boundingBox`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param parcelAcreUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDAcresPut(parcelID: number, parcelAcreUpdateDto?: ParcelAcreUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<ParcelMinimalDto>;
    public parcelsParcelIDAcresPut(parcelID: number, parcelAcreUpdateDto?: ParcelAcreUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParcelMinimalDto>>;
    public parcelsParcelIDAcresPut(parcelID: number, parcelAcreUpdateDto?: ParcelAcreUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParcelMinimalDto>>;
    public parcelsParcelIDAcresPut(parcelID: number, parcelAcreUpdateDto?: ParcelAcreUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDAcresPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ParcelMinimalDto>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/acres`,
            parcelAcreUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param parcelZoneAssignmentFormDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDEditZoneAssignmentsPost(parcelID: number, parcelZoneAssignmentFormDto?: ParcelZoneAssignmentFormDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public parcelsParcelIDEditZoneAssignmentsPost(parcelID: number, parcelZoneAssignmentFormDto?: ParcelZoneAssignmentFormDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public parcelsParcelIDEditZoneAssignmentsPost(parcelID: number, parcelZoneAssignmentFormDto?: ParcelZoneAssignmentFormDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public parcelsParcelIDEditZoneAssignmentsPost(parcelID: number, parcelZoneAssignmentFormDto?: ParcelZoneAssignmentFormDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDEditZoneAssignmentsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/edit-zone-assignments`,
            parcelZoneAssignmentFormDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<ParcelMinimalDto>;
    public parcelsParcelIDGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParcelMinimalDto>>;
    public parcelsParcelIDGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParcelMinimalDto>>;
    public parcelsParcelIDGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParcelMinimalDto>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDGetSupplyEntriesGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelSupplyDetailDto>>;
    public parcelsParcelIDGetSupplyEntriesGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelSupplyDetailDto>>>;
    public parcelsParcelIDGetSupplyEntriesGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelSupplyDetailDto>>>;
    public parcelsParcelIDGetSupplyEntriesGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDGetSupplyEntriesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelSupplyDetailDto>>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/getSupplyEntries`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDHistoryGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelHistoryDto>>;
    public parcelsParcelIDHistoryGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelHistoryDto>>>;
    public parcelsParcelIDHistoryGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelHistoryDto>>>;
    public parcelsParcelIDHistoryGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDHistoryGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelHistoryDto>>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/history`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDMapPopupGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<ParcelPopupDto>;
    public parcelsParcelIDMapPopupGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParcelPopupDto>>;
    public parcelsParcelIDMapPopupGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParcelPopupDto>>;
    public parcelsParcelIDMapPopupGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDMapPopupGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParcelPopupDto>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/map-popup`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param parcelUpdateOwnershipRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDOwnershipPut(parcelID: number, parcelUpdateOwnershipRequestDto?: ParcelUpdateOwnershipRequestDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public parcelsParcelIDOwnershipPut(parcelID: number, parcelUpdateOwnershipRequestDto?: ParcelUpdateOwnershipRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public parcelsParcelIDOwnershipPut(parcelID: number, parcelUpdateOwnershipRequestDto?: ParcelUpdateOwnershipRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public parcelsParcelIDOwnershipPut(parcelID: number, parcelUpdateOwnershipRequestDto?: ParcelUpdateOwnershipRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDOwnershipPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/ownership`,
            parcelUpdateOwnershipRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param waterAccountID 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDUpdateWaterAccountWaterAccountIDPost(parcelID: number, waterAccountID: number, body?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public parcelsParcelIDUpdateWaterAccountWaterAccountIDPost(parcelID: number, waterAccountID: number, body?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public parcelsParcelIDUpdateWaterAccountWaterAccountIDPost(parcelID: number, waterAccountID: number, body?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public parcelsParcelIDUpdateWaterAccountWaterAccountIDPost(parcelID: number, waterAccountID: number, body?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDUpdateWaterAccountWaterAccountIDPost.');
        }

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling parcelsParcelIDUpdateWaterAccountWaterAccountIDPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/update-water-account/${encodeURIComponent(String(waterAccountID))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDWaterAccountParcelsGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountParcelDto>>;
    public parcelsParcelIDWaterAccountParcelsGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountParcelDto>>>;
    public parcelsParcelIDWaterAccountParcelsGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountParcelDto>>>;
    public parcelsParcelIDWaterAccountParcelsGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDWaterAccountParcelsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountParcelDto>>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/waterAccountParcels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDWellsGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellLocationDto>>;
    public parcelsParcelIDWellsGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellLocationDto>>>;
    public parcelsParcelIDWellsGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellLocationDto>>>;
    public parcelsParcelIDWellsGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDWellsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellLocationDto>>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/wells`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsParcelIDZonesGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<ParcelDetailDto>;
    public parcelsParcelIDZonesGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParcelDetailDto>>;
    public parcelsParcelIDZonesGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParcelDetailDto>>;
    public parcelsParcelIDZonesGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling parcelsParcelIDZonesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParcelDetailDto>(`${this.basePath}/parcels/${encodeURIComponent(String(parcelID))}/zones`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsReviewPut(requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public parcelsReviewPut(requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public parcelsReviewPut(requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public parcelsReviewPut(requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/parcels/review`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param searchString 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parcelsSearchSearchStringGet(searchString: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelDisplayDto>>;
    public parcelsSearchSearchStringGet(searchString: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelDisplayDto>>>;
    public parcelsSearchSearchStringGet(searchString: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelDisplayDto>>>;
    public parcelsSearchSearchStringGet(searchString: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (searchString === null || searchString === undefined) {
            throw new Error('Required parameter searchString was null or undefined when calling parcelsSearchSearchStringGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelDisplayDto>>(`${this.basePath}/parcels/search/${encodeURIComponent(String(searchString))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
